<!-- eslint-disable max-len -->
<template>
  <div id="product-selection" class="section-wrapper flex flex-col lg:flex-row justify-between relative">
      <div class="left-part relative w-full lg:w-2/3 overflow-hidden">
          <img src="~@/assets/images/page-img.jpg" alt="DSM" class="w-full h-full">
          <!-- <div class="absolute top-6 md:top-12 left-6 md:left-12">
              <img src="~@/assets/images/logo-proAct.svg" alt="DSM" class="w-1/3 md:w-3/5 xl:w-full">
          </div> -->
          <div class="absolute -bottom-12 2xl:-bottom-0 right-0">
              <img src="~@/assets/images/rectangle-bg.png" alt="DSM" class="w-full">
          </div>
          <div class="absolute left-0 right-0 bottom-4 md:bottom-8 w-full pl-4 xl:pl-12 pr-4 xl:pr-8 flex justify-end items-center">
              <button
                :disabled="!hasProact360Access"
                @click="onProductSelect('proact360')"
                class="text-base md:text-2xl font-semibold inline-flex items-center px-4 md:px-8 2xl:px-10 py-1 md:py-3 2xl:py-4 rounded-full"
                :class="{'cursor-not-allowed border-2 border-grey-darkLight bg-grey-lightest text-grey-dark':!hasProact360Access, 'bg-button bg-green-brand text-white':hasProact360Access}"
              >
                  Proceed to ProAct360
                  <svg width="26" height="26" class="ml-2" :class="{'fill-grey-dark':!hasProact360Access, 'fill-white': hasProact360Access}" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 0C5.83192 0 0 5.83192 0 13C0 20.1681 5.83192 26 13 26C20.1681 26 26 20.1681 26 13C26 5.83192 20.1681 0 13 0ZM13 24.375C6.7277 24.375 1.625 19.2723 1.625 13C1.625 6.7277 6.7277 1.625 13 1.625C19.2723 1.625 24.375 6.7277 24.375 13C24.375 19.2723 19.2723 24.375 13 24.375ZM18.4494 12.4256C18.7669 12.743 18.7669 13.2572 18.4494 13.5744L14.3869 17.6369C14.2283 17.7956 14.0203 17.875 13.8125 17.875C13.6047 17.875 13.3967 17.7956 13.2381 17.6369C12.9206 17.3195 12.9206 16.8053 13.2381 16.4881L15.9136 13.8125H8.125C7.6763 13.8125 7.3125 13.4491 7.3125 13C7.3125 12.5509 7.6763 12.1875 8.125 12.1875H15.9136L13.2381 9.51194C12.9206 9.19445 12.9206 8.68034 13.2381 8.36306C13.5555 8.04578 14.0697 8.04558 14.3869 8.36306L18.4494 12.4256Z" fill="currentColor"/>
                  </svg>
              </button>
          </div>
      </div>
      <div class="right-part w-full lg:w-1/3 bg-brand-800 relative overflow-hidden py-8 lg:py-0">
          <img src="~@/assets/images/page-bg2.jpg" alt="DSM" class="w-full absolute bottom-0 hidden lg:block">
          <div class="lg:absolute top-0 lef-0 w-full h-full flex flex-col items-center justify-center px-10 2xl:px-12">
              <img src="~@/assets/images/logo-ronozyme.png" alt="DSM" class="w-1/2 md:w-auto lg:w-4/5 xl:w-auto">
              <h3 class="font-medium text-lg md:text-2xl xl:text-4xl my-4 lg:my-6 xl:my-8 text-center text-black">Looking for the ProAct calculator?</h3>
              <button
                @click="onProductSelect('proact')"
                class="flex items-center"
                :class="{'cursor-not-allowed text-grey-darkLight':!hasProactAccess}" :disabled="!hasProactAccess"
              >
                  <span class="text-base xl:text-xl font-semibold text-brand underline">Proceed to ProAct</span>
                  <span class="ml-2">
                      <svg class="fill-brand" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 0C4.48609 0 0 4.48609 0 10C0 15.5139 4.48609 20 10 20C15.5139 20 20 15.5139 20 10C20 4.48609 15.5139 0 10 0ZM10 18.75C5.17516 18.75 1.25 14.8248 1.25 10C1.25 5.17516 5.17516 1.25 10 1.25C14.8248 1.25 18.75 5.17516 18.75 10C18.75 14.8248 14.8248 18.75 10 18.75ZM14.1919 9.55813C14.4361 9.80234 14.4361 10.1978 14.1919 10.4419L11.0669 13.5669C10.9448 13.6889 10.7848 13.75 10.625 13.75C10.4652 13.75 10.3052 13.6889 10.1831 13.5669C9.93891 13.3227 9.93891 12.9272 10.1831 12.6831L12.2413 10.625H6.25C5.90484 10.625 5.625 10.3455 5.625 10C5.625 9.65453 5.90484 9.375 6.25 9.375H12.2413L10.1831 7.31687C9.93891 7.07266 9.93891 6.67719 10.1831 6.43313C10.4273 6.18906 10.8228 6.18891 11.0669 6.43313L14.1919 9.55813Z" fill="current"/>
                      </svg>
                  </span>
              </button>
          </div>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ProductSelection',

  mounted() {
    this.$store.commit('setNavigation')
  },

  methods: {
    ...mapActions({
      selectProduct: 'productSelect/onProductSelection',
    }),

    onProductSelect(product) {
      this.selectProduct({ selectedProduct: product })
          .then(() => {
            this.errors = []
            this.$router.push({name: 'home'})
          })
          .catch((err) => {
            console.log(err.data)
            this.errors = err.data.errors
            // TODO: error handling
          })
    }
  },

  computed: {
    ...mapGetters({
      hasProactAccess: 'auth/hasProactAccess',
      hasProact360Access: 'auth/hasProact360Access'
    })
  },
};
</script>

<style scoped>

</style>
